import { create } from "zustand";
import {
  IJurisdictionInfo,
  IProjectRecordList,
  IProjectReportType,
  IRecordDetails,
  IRecordTimelineData,
  IRequestProjectRecordsData,
} from "../models/interface";
import { pageSizeConfigs } from "../../../config/commonConfig";
import { OrganizationService, ProjectListService } from "../services";
import { RecordMenuKeys } from "../models/enums";
import { IHttpError } from 'models/interface';

interface IToastMessages {
  success: string;
  error: string;
  loading: string;
}
interface DrawerOptions {
  title: string;
  submitBtnLabel: string;
  key: number;
  data: null | IRecordDetails;
  toastMessage: null | IToastMessages;
}
interface ProjectRecordState {
  projectRecords: IProjectRecordList[];
  oldProjectRecords: IProjectRecordList[];
  firstLoad: boolean;
  showTableLoader: boolean;
  recordsCount: number | null;
  actualRecordsCount: number | null;
  currentPageSize: number;
  currentPage: number;
  currentSortColumn: string;
  currentSortOrder: string;
  showDeleted: boolean;
  drawerState: boolean;
  drawerOptions: DrawerOptions;
  recordDetails: IRecordDetails | null;
  search: string;
  question_id: string;
  showConfirmDelete: boolean;
  jurisdictionObj: IJurisdictionInfo | null;
  isFetchingJurisdictionData: boolean;
  activeMenuKey: number;
  amendRecordName: string;
  reportTypeList: IProjectReportType[] | null;
  downloadingKeys: number[];
  showCloneRecord: boolean;
  isMixRoles: boolean;
  showConfirmRestore: boolean;
  gearAction: boolean;
  showConfirmHideUnhide: boolean;
  recordsView: 'table' | 'timeline';
  showTimelineLoader: boolean;
  timeDataFetchError: IHttpError | null;
  recordsTimelineData: IRecordTimelineData[];
  expandTimeline: boolean;
  recordsProjectSlug: string;

  resetState: () => void;
  setProjectRecords: (projects: IProjectRecordList[] | null) => void;
  setOldProjectRecords: (projects: IProjectRecordList[] | null) => void;
  setFirstLoad: (load: boolean) => void;
  setShowTableLoader: (value: boolean) => void;
  setRecordsCount: (count: number) => void;
  setCurrentPageSize: (pageSize: number) => void;
  setCurrentPage: (page: number) => void;
  setCurrentSortColumn: (column: string) => void;
  setCurrentSortOrder: (order: string) => void;
  setShowDeleted: (value: boolean) => void;
  refreshRecordList: (params: IRequestProjectRecordsData) => Promise<void>;
  setDrawerState: (state: boolean) => void;
  setDrawerOptions: (key?: number, recordInfo?: IRecordDetails) => void;
  resetDrawer: () => void;
  setRecordDetails: (recordDetails: IRecordDetails | null) => void;
  resetRecordListOnSubmit: () => void;
  setSearch: (search: string) => void;
  setQuestionId: (question_id: string) => void;
  setShowConfirmDelete: (value: boolean) => void;
  setJurisdictionObj: (data: IJurisdictionInfo | null) => void;
  setIsFetchingJurisdictionData: (data: boolean) => void;
  setActiveMenuKey: (value: number) => void;
  setAmendRecordName: (value: string) => void;
  getReportTypeList: () => Promise<void>;
  setDownloadingKeys: (value: number) => void;
  setShowCloneRecord: (value: boolean) => void;
  setShowConfirmRestore: (value: boolean) => void;
  setGearAction: (value: boolean) => void;
  setShowConfirmHideUnhide: (value: boolean) => void;
  setRecordsView: (value: 'table' | 'timeline') => void;
  refreshRecordTimeline: (project_slug: string, params: { question_id: string | null; search?: string | null;}) => Promise<void>;
  setExpandTimeline: (value: boolean) => void;
}

const useStore = create<ProjectRecordState>((set) => ({
  // state
  projectRecords: [],
  oldProjectRecords: [],
  firstLoad: true,
  showTableLoader: true,
  recordsCount: null,
  actualRecordsCount: null,
  currentPageSize: pageSizeConfigs.DEFAULT_PAGE_SIZE,
  currentPage: 1,
  currentSortColumn: "record,effective",
  currentSortOrder: "asc,desc",
  drawerState: false,
  drawerOptions: {
    title: "Add New Record",
    submitBtnLabel: "Create New Record",
    key: 0,
    data: null,
    toastMessage: null,
  },
  recordDetails: null,
  search: '',
  question_id: '0',
  showConfirmDelete: false,
  jurisdictionObj: null,
  isFetchingJurisdictionData: false,
  activeMenuKey: 0,
  amendRecordName: "Record",
  reportTypeList: null,
  downloadingKeys: [],
  showCloneRecord: false,
  isMixRoles: false,
  showConfirmRestore: false,
  showDeleted: false,
  gearAction: false,
  showConfirmHideUnhide: false,
  recordsView: 'table',
  showTimelineLoader: true,
  timeDataFetchError: null,
  recordsTimelineData: [],
  expandTimeline: true,
  recordsProjectSlug: '',

  // actions
  resetState: () => {
    set(() => ({
      projectRecords: [],
      oldProjectRecords: [],
      firstLoad: true,
      recordsCount: null,
      actualRecordsCount: null,
      showTableLoader: true,
      projectsCount: 0,
      currentPageSize: pageSizeConfigs.DEFAULT_PAGE_SIZE,
      currentPage: 1,
      currentSortColumn: "record,effective",
      currentSortOrder: "asc,desc",
      showDeleted: false,
      search: '',
      question_id: '0',
      drawerOptions: {
        title: "Create New Project",
        submitBtnLabel: "Create New Project",
        key: 0,
        data: null,
        toastMessage: null,
      },
      activeMenuKey: 0,
      amendRecordName: "Record",
      showCloneRecord: false,
      isMixRoles: false,
      showConfirmRestore: false,
      gearAction: false,
      showConfirmHideUnhide: false,
      recordsView: 'table',
      showTimelineLoader: true,
      timeDataFetchError: null,
      recordsTimelineData: [],
    }));
  },
  resetDrawer: () => {
    set(() => ({
      drawerOptions: {
        title: "Create New Record",
        submitBtnLabel: "Create New Record",
        key: 0,
        data: null,
        toastMessage: null,
      },
      recordDetails: null,
      jurisdictionObj: null,
      activeMenuKey:0,
      amendRecordName: "Record",
      gearAction: false
    }));
  },
  resetRecordListOnSubmit: () => {
    set(() =>({
      currentPageSize: pageSizeConfigs.DEFAULT_PAGE_SIZE,
      currentPage: 1,
      sort_column: "record,effective",
      sort_order: "asc,desc",
    }));
  },
  setRecordDetails: (recordDetails) => {
    set(() => ({
      recordDetails: recordDetails,
    }));
  },
  setFirstLoad: (load) => {
    set(() => ({
      firstLoad: load,
    }));
  },
  setProjectRecords: (records) => {
    set(() => ({
      projectRecords: records ?? [],
    }));
  },
  setOldProjectRecords: (records) => {
    set(() => ({
        oldProjectRecords: records ?? []
    }))
  },
  setShowTableLoader: (value) => {
    set(() => ({
      showTableLoader: value,
    }));
  },
  setRecordsCount: (count) => {
    set(() => ({
      recordsCount: count,
    }));
  },
  setCurrentPageSize: (pageSize) => {
    set(() => ({
      currentPageSize: pageSize,
      currentPage: 1,
      projectRecords: [],
      firstLoad: true,
    }));
  },
  setCurrentPage: (page) => {
    set(() => ({
      currentPage: page,
      projectRecords: [],
      firstLoad: true,
    }));
  },
  setCurrentSortColumn: (column) => {
    set(() => ({
      currentSortColumn: column,
      projectRecords: [],
      firstLoad: true,
    }));
  },
  setCurrentSortOrder: (order) => {
    set(() => ({
      currentSortOrder: order,
      projectRecords: [],
    }));
  },
  setShowDeleted: (value) => {
    set(() => ({
      showDeleted: value
    }));
  },
  setDrawerState: (state) => {
    set(() => ({
      expandTimeline: !state,
      drawerState: state,
    }));
  },
  setJurisdictionObj: (data: any) => {
    set(() => ({
      jurisdictionObj: data,
    }));
  },
  setIsFetchingJurisdictionData: (data: boolean) => {
    set(() => ({
      isFetchingJurisdictionData: data,
    }));
  },
  setDrawerOptions: (key, recordInfo) => {
    const titles: any = {
      0: "Add New Record",
    };
    titles[RecordMenuKeys.EDIT_RECORD] = `Edit ${recordInfo?.record ?? ""}`;
    titles[RecordMenuKeys.AMEND_RECORD] = `Amend ${recordInfo?.record ?? ""}`

    const toastMessages: any = {
      0: {
        loading: "Creating record...",
        success: "Record created successfully!",
        error: "Record creation failed. Please try again!",
      },
    };

    toastMessages[RecordMenuKeys.EDIT_RECORD] = {
      loading: "Updating record...",
      success: "Record updated successfully!",
      error: "Error in updating record. Please try again!",
    };

    toastMessages[RecordMenuKeys.AMEND_RECORD] = {
      loading: "Amending record...",
      success: "Record Amended successfully!",
      error: "Error in amending record. Please try again!",
    };

    const submitBtnLabelTitle: any = {
      0: "Create New Record",
    };
    submitBtnLabelTitle[RecordMenuKeys.EDIT_RECORD] = "Save Changes";
    submitBtnLabelTitle[RecordMenuKeys.AMEND_RECORD] = "Amend Record";

    set(() => ({
      drawerOptions: {
        key: key ?? 0,
        title: titles[key ?? 0],
        submitBtnLabel: submitBtnLabelTitle[key ?? 0],
        data: recordInfo ?? null,
        toastMessage: toastMessages[key ?? 0],
      },
    }));
  },
  setSearch: (search) => {
    set(() => ({
      search: search,
      projectRecords: []
    }));
  },
  setQuestionId: (question_id) => {
    set(() => ({
      question_id: question_id,
      projectRecords: []
    }));
  },
  refreshRecordList: async (params) => {
    set(() => ({ showTableLoader: true }));
    try {
      const result = await new ProjectListService().getProjectRecordList(
        params
      );
      const { records, count, actual_count, project_slug } = result.data.data;
      set(() => ({
        projectRecords: count > 0 ? [...records.record_list] : [],
        oldProjectRecords: count > 0 ?[...records.record_list] : [],
        recordsCount: count,
        actualRecordsCount: actual_count,
        showTableLoader: false,
        firstLoad: false,
        isMixRoles: records.is_mix_roles,
        recordsProjectSlug: project_slug,
      }));
    } catch (error) {
      set(() => ({ showTableLoader: false }));
      console.error(error);
    }
  },
  setShowConfirmDelete: (state) => {
    set(() => ({
      showConfirmDelete: state,
    }));
  },
  setActiveMenuKey: (value) => {
    set(() => ({
      activeMenuKey: value,
    }));
  },
  setAmendRecordName: (value) => {
    set(() => ({
      amendRecordName: value,
    }));
  },
  getReportTypeList: async () => {
    try {
      const { data } = await new OrganizationService().getProjectReportTypeList();
      set(() => ({
        reportTypeList: data.data.reportTypeList 
      }));
    } catch (error) {
      console.error(error);
    }
  },
  setDownloadingKeys: (value) => {
    set((state) => {
      if (state.downloadingKeys.includes(value)) {
        return { downloadingKeys: [...state.downloadingKeys.filter(key => key !== value)] }
      }
      return { downloadingKeys: [...state.downloadingKeys, value] }
    });
  },
  setShowCloneRecord: (state) => {
    set(() => ({
      showCloneRecord: state,
    }));
  },
  setShowConfirmRestore: (state) => {
    set(() => ({
      showConfirmRestore: state,
    }));
  },
  setGearAction: (value) => {
    set(() => ({
      gearAction: value,
    }));
  },

  setShowConfirmHideUnhide: (state) => {
    set(() => ({
      showConfirmHideUnhide: state,
    }));
  },
  setExpandTimeline: (value: boolean) => {
    set(() => ({ expandTimeline: value }));
  },
  setRecordsView: (state) => {
    set(() => ({
      recordsView: state,
    }));
  },
  refreshRecordTimeline: async (project_slug, params) => {
    set(() => ({ showTimelineLoader: true }));
    try {
      const result = await new ProjectListService().getRecordsTimelineViewData(project_slug, params);
      const { records } = result.data.data;
      set(() => ({
        recordsTimelineData: records && records.length > 0 ? [...records] : [],
        showTimelineLoader: false,
        firstLoad: false,
        timeDataFetchError: null
      }));
    } catch (error: any) {
      set(() => ({ showTimelineLoader: false, timeDataFetchError: error }));
      console.error(error);
    }
  },
}));

export const useProjectRecordsStore = useStore;
