import { create } from "zustand";
import {
  ICreateEditQuestionDetails,
  IProjectDetails,
  IProjectQuestionFlattenTree,
  IProjectQuestions,
  IProjectQuestionTree,
  IProjectSuggestiveTextHistory,
  IQuestionType,
  ISaveQuestionDetails,
} from "../models/interface";
import { ProjectListService } from "../services";
import { IHttpError } from "models/interface";
import { Key } from 'antd/es/table/interface';
import { UniqueCheckStatus } from './createNewProjectStore';

interface ProjectDetailsState {
  projectDetails: IProjectDetails | null;
  projectQuestionTree: IProjectQuestionTree[];
  projectOldQuestionTree: IProjectQuestionTree[];
  isLoadingProjectDetails: boolean;
  error: IHttpError | null;
  questionTypeList: IQuestionType[];
  typeListError: IHttpError | null;
  createEditQuestionDetails: null | ICreateEditQuestionDetails;
  treeExpandedKeys: Key[];
  isSubmittingQuestion: boolean;
  variableCheck: UniqueCheckStatus;
  currentHoveredKey: Key | null;
  manuallyModifiedKeys: Key[];
  expandedQuestionKey: Key | null;
  needPageRefresh: boolean;
  currentMode: "question" | "record";
  recordCount: number;
  questionCount: number;
  showQuestionTypeChangeModal: boolean;
  isReportCreated: boolean;
  showSuggestiveTextHistory: boolean;
  projectSuggestiveTextHistory: IProjectSuggestiveTextHistory[],

  getProjectDetails: (project_slug: string) => Promise<void>;
  updateProjectQuestionTree: (questions: IProjectQuestionTree[], key?: Key) => boolean;
  clearProjectDetails: () => void;
  updateProjectQuestionTreeAfterSortSave: (questions: IProjectQuestions[] | null) => void;
  getSetQuestionTypeList: () => Promise<void>;
  setCreateEditQuestionDetails: (data?: ICreateEditQuestionDetails) => void;
  updateExpandedKeys: (keys?: Key[]) => void;
  checkAndUpdateExpandedKeys:() => void;
  setIsSubmittingQuestion: (value: boolean) => void;
  setVariableCheck: (status: UniqueCheckStatus) => void;
  setCurrentHoveredKey: (key?: Key) => void;
  updateManuallyModifiedKeys: (key: Key, mode: 'add'|'remove') => void;
  setExpandedQuestionKey: (key?: Key) => void;
  setNeedPageRefresh: (value: boolean) => void;
  setIsReportCreated: (value: boolean) => void;
  setCurrentMode: (value: "question" | "record") => void;
  setRecordCount: (recordCount: number) => void;
  setShowQuestionTypeChangeModal: (showQuestionTypeChangeModal: boolean) => void;
  setShowSuggestiveTextHistory: (value: boolean) => void;
  getProjectSuggestiveTextHistory: (value: string) => Promise<void>;
  clearProjectSuggestiveTextHistory: () => void;
}

const useStore = create<ProjectDetailsState>((set) => ({
  projectDetails: null,
  projectQuestionTree: [],
  projectOldQuestionTree: [],
  isLoadingProjectDetails: true,
  error: null,
  questionTypeList: [],
  typeListError: null,
  createEditQuestionDetails: null,
  treeExpandedKeys: [],
  isSubmittingQuestion: false,
  variableCheck: null,
  currentHoveredKey: null,
  manuallyModifiedKeys: [],
  expandedQuestionKey: null,
  needPageRefresh: false,
  currentMode: "question",
  recordCount: 0,
  questionCount: 0,
  showQuestionTypeChangeModal: false,
  isReportCreated: false,
  showSuggestiveTextHistory: false,
  projectSuggestiveTextHistory: [],

  getProjectDetails: async (project_slug) => {
    set(() => ({
      questionCount: 0,
      recordCount: 0
    }));
    try {
      const projectDetails = await new ProjectListService().getProjectDetailsData(project_slug);
      const _projectQuestionTree = makeQuestionTree(projectDetails.data.data.project_details.questions ?? [], 0);

      set(() => ({
        isLoadingProjectDetails: false,
        projectDetails: projectDetails.data.data.project_details,
        projectQuestionTree: _projectQuestionTree,
        projectOldQuestionTree: JSON.parse(JSON.stringify(_projectQuestionTree)),
        treeExpandedKeys: getAllParentKeys(_projectQuestionTree),
        error: null,
        questionCount: projectDetails.data.data.project_details.question_count,
        recordCount: projectDetails.data.data.project_details.record_count,
        isReportCreated: projectDetails.data.data.project_details.is_report_created
      }));
    } catch (error: any) {
      console.log(error);
      console.log(error?.code);

      set((state) => ({
        ...state,
        isLoadingProjectDetails: false,
        error: error
          ? {
            ...error,
            code: error?.code ?? 500,
            message: error?.message ?? "Something went wrong",
          }
          : null,
      }));
    }
  },
  updateProjectQuestionTreeAfterSortSave: (question) => {
    const _projectQuestionTree = makeQuestionTree(question ?? [], 0);
    set(() => ({
      projectQuestionTree: _projectQuestionTree,
      projectOldQuestionTree: JSON.parse(JSON.stringify(_projectQuestionTree)),
      error: null,
      createEditQuestionDetails: null,
      manuallyModifiedKeys: [],
      questionCount: question?.length ?? 0
    }));
  },
  updateProjectQuestionTree: (questions, key) => {
    if(key){
      if(canBeUpdateTheDropPosition(JSON.parse(JSON.stringify([...questions])), key)){

        set((state) => {
          let currentExpendedKeys = [...state.treeExpandedKeys];
          const findNode = findNodeByKey(questions, key);
          if(findNode){
            currentExpendedKeys = [...currentExpendedKeys, findNode.parent_id];
          }
          return {
            projectQuestionTree: questions ?? [],
            treeExpandedKeys: currentExpendedKeys
          }
        });
        return true;
      }
      else{
        set((state) => ({
          projectQuestionTree: updateTreeAfterSort(state.projectQuestionTree) ?? []
        }));
        return false;
      }
    }
    else{
      set(() => ({
        projectQuestionTree: questions ?? []
      }));
      return true;
    }
  },
  clearProjectDetails: () => {
    set(() => ({
      isLoadingProjectDetails: true,
      projectDetails: null,
      projectQuestionTree: [],
      error: null,
      createEditQuestionDetails: null,
      isSubmittingQuestion: false,
      variableCheck: null,
      manuallyModifiedKeys: [],
      treeExpandedKeys: [],
      currentMode: "question",
      needPageRefresh: false,
      recordCount: 0,
      questionCount: 0,
      expandedQuestionKey: null,
      showQuestionTypeChangeModal: false,
      isReportCreated: false,
    }));
  },
  getSetQuestionTypeList: async () => {
    try {
      const { data } = await new ProjectListService().getQuestionTypeList();
      set(() => ({ questionTypeList: data.data.questionTypeList, typeListError: null }))
    } catch (error: any) {
      console.log(error);
      set(() => ({
        typeListError: error
          ? {
            ...error,
            code: error?.code ?? 500,
            message: error?.message ?? "Something went wrong",
          }
          : null,
      }));
    }

  },
  setCreateEditQuestionDetails: (data) => {
    set(() => ({ createEditQuestionDetails: data ?? null }))
  },
  updateExpandedKeys: (keys) => {
    set(() => ({ treeExpandedKeys: keys ?? [] }))
  },
  checkAndUpdateExpandedKeys: () => {
    set((state) => {
      let currentExpendedKeys = [...state.treeExpandedKeys];
      currentExpendedKeys.forEach(eachKey => {
        const findNode = findNodeByKey(state.projectQuestionTree, eachKey);
        if(!findNode?.children?.length){
          currentExpendedKeys = currentExpendedKeys.filter(each=>each !== eachKey);
        }
      })

      return { treeExpandedKeys: currentExpendedKeys ?? [] }
    })
  },
  updateManuallyModifiedKeys:(key, mode) =>{
    set((state) => {
      let currentManuallyModifiedKeys = [...state.manuallyModifiedKeys];
      if(mode === 'add'){
        currentManuallyModifiedKeys = [...currentManuallyModifiedKeys, key];
      }
      else{
        currentManuallyModifiedKeys = currentManuallyModifiedKeys.filter(each => each !== key)
      }

      return { manuallyModifiedKeys: currentManuallyModifiedKeys ?? [] }
    })
  },
  setIsSubmittingQuestion: (value) => {
    set(() => ({ isSubmittingQuestion: value }))
  },
  setVariableCheck: (status) => {
    set(() => ({ variableCheck: status }))
  },
  setCurrentHoveredKey: (key) => {
    set(() => ({ currentHoveredKey: key ?? null }))
  },
  setExpandedQuestionKey: (key?: Key) => {
    set((state) => ({ expandedQuestionKey: state.expandedQuestionKey !== key ? key : null }))
  },
  setNeedPageRefresh: (value) => {
    set(() => ({ needPageRefresh: value }))
  },
  setIsReportCreated: (value) => {
    set(() => ({ isReportCreated: value }))
  },
  setCurrentMode: (value) => {
    set(() => ({ currentMode: value }))
  },
  setRecordCount: (recordCount) => {
    set(() => ({ recordCount }))
  },
  setShowQuestionTypeChangeModal: (showQuestionTypeChangeModal) => {
    set(() => ({ showQuestionTypeChangeModal }))
  },
  setShowSuggestiveTextHistory: (value) => {
    set(() => ({
      showSuggestiveTextHistory: value,
    }));
  },
  getProjectSuggestiveTextHistory: async (project_slug) => {
    try {
      const { data } = await new ProjectListService().getProjectSuggestiveTextHistory(project_slug);
      set(() => ({projectSuggestiveTextHistory: data.data.projectSuggestiveTextHistory ?? [] }));
    } catch (error) {
      console.log(error);
    }
  },
  clearProjectSuggestiveTextHistory: () => {
    set(() => ({projectSuggestiveTextHistory: [] }));
  }
}));

const makeQuestionTree = (
  items: IProjectQuestions[],
  id: number,
  link: string = "parent_question_id",
  layer: number = 1,
  layer_string: string = ""
): IProjectQuestionTree[] => {
  return items
    .filter((item) => item.parent_question_id === id)
    .sort((a, b) => a.question_order - b.question_order)
    .map((item, index) => ({
      key: item.question_id,
      title: item.question,
      data: { ...item, old_question_parent_id: item.parent_question_id, old_index_position: index, old_layer_string: layer_string !== "" ? `${layer_string}.${index + 1}` : `${index + 1}` },
      index_position: index,
      layer: layer,
      parent_id: item.parent_question_id,
      layer_string: layer_string !== "" ? `${layer_string}.${index + 1}` : `${index + 1}`,
      sibling_count: items.filter((item) => item.parent_question_id === id).length,
      className: 'questionWrapper',
      children: makeQuestionTree(
        items,
        item.question_id,
        "parent_question_id",
        layer + 1,
        layer_string !== "" ? `${layer_string}.${index + 1}` : `${index + 1}`
      ),
    }));
};

const updateTreeAfterSort = (
  items: IProjectQuestionTree[],
  parent_id: number = 0,
  parentLayerText: string = '',
  layer: number = 1,
): IProjectQuestionTree[] => {
  items.forEach((item, index) => {
    const newLayerText = parentLayerText ? `${parentLayerText}.${index + 1}` : `${index + 1}`;

    item.layer = layer;
    item.layer_string = newLayerText;
    item.parent_id = parent_id;
    item.index_position = index;
    item.sibling_count = items.length;

    if (item.children) {
      updateTreeAfterSort(item.children, item.key as number, newLayerText, layer + 1);
    }
  });

  return items;
}

const findNodeByKey = (items: IProjectQuestionTree[], targetKey: Key): IProjectQuestionTree | null =>{

  for (const node of items) {
    if (node.key === targetKey) {
      return node;
    }

    if (node.children && node.children.length > 0) {
      const foundNode = findNodeByKey(node.children, targetKey);
      if (foundNode) {
        return foundNode;
      }
    }
  }

  return null;
}

const getAllParentKeys = (items: IProjectQuestionTree[]) => {
  const parentIds:Key[] = [];

  function traverse(nodes: IProjectQuestionTree[]) {
    for (const node of nodes) {
      if (node?.children && node?.children?.length > 0) {
        parentIds.push(node.key);
        traverse(node.children);
      }
    }
  }

  traverse(items);

  return parentIds;
}

const findMaxDepthOfTree = (items: IProjectQuestionTree[]) => {
  let maxDepth = 0;
      
  function traverseMaxDepth(nodes: IProjectQuestionTree[], depth: number) {
    if (depth > maxDepth) {
      maxDepth = depth;
    }

    for (const node of nodes) {
      if (node.children && node.children?.length > 0) {
        traverseMaxDepth(node?.children, depth + 1);
      }
    }
  }

  traverseMaxDepth(items, 1);

  return maxDepth;
}

const canBeUpdateTheDropPosition = (items: IProjectQuestionTree[], key: Key) => {
  const _items = JSON.parse(JSON.stringify([...items]));
  
  const findNode = findNodeByKey(_items, key);
  
  if(findNode && findNode?.parent_id >= 0){
    if(findNode.parent_id === 0){
      return true;
    }
    const findParentNode = findNodeByKey(_items, findNode.parent_id);
    if(findParentNode?.children){
      const maxDepth = findMaxDepthOfTree(findParentNode.children);
      if(maxDepth + findParentNode.layer <= 4){
        return true;
      }
    }
  }
  return false;
}

const moveChildElementOfTree = (items: IProjectQuestionTree[], parentId: number, fromIndex: number, toIndex: number): IProjectQuestionTree[] => {
  const _items: IProjectQuestionTree[] = items;

  if (toIndex >= 0) {
    if (parentId > 0) {
      _items.forEach((item) => {
        if (item.key === parentId && item.children && toIndex < item.children.length) {
          const element = item.children.splice(fromIndex, 1)[0];
          item.children.splice(toIndex, 0, element);
          item.children = item.children.map(each => each)
        }
        else if(item?.children?.length){
          moveChildElementOfTree(item.children, parentId,  fromIndex, toIndex)
        }
      });
    }
    else if (toIndex < _items.length) {
        const element = _items.splice(fromIndex, 1)[0];
        _items.splice(toIndex, 0, element);
      }
    
  }

  return updateTreeAfterSort(_items.map(each => each));

}

const questionTreeToFlattenQuestionList = (items: IProjectQuestionTree[]): IProjectQuestionFlattenTree[] => {
  const flattened: IProjectQuestionFlattenTree[] = [];

  function flattenNode(node: IProjectQuestionTree): void {
    flattened.push(node);

    if (node.children && node.children.length > 0) {
      for (const child of node.children) {
        flattenNode(child);
      }
    }
  }

  for (const node of items) {
    flattenNode(node);
  }

  return flattened;
}

const addQuestionToTree = (
  tree: IProjectQuestionTree[],
  question: IProjectQuestionTree,
  parentQuestion: null | IProjectQuestionTree
): IProjectQuestionTree[] => {
  if (!tree.length) {
    return [question];
  }

  if (question.parent_id === tree[0].parent_id) {
    const updatedTree = [...tree];
    updatedTree.splice(question.index_position, 0, question);
    return updatedTree;
  }

  return tree.map((node) => {
    if (node.data.question_id === question.parent_id) {
      const updatedChildren = node.children ? [...node.children] : [];
      updatedChildren.splice(question.index_position, 0, question);
      return { ...node, children: updatedChildren };
    }

    if (node.children && node.children.length > 0) {
      const updatedChildren = addQuestionToTree(node.children, question, null);
      return { ...node, children: updatedChildren };
    }

    return node;
  });
};

const removeFromTree = (
  editingKey: string | number,
  editingParentKey: number,
  tree: IProjectQuestionTree[],
  parentQuestion: null | IProjectQuestionTree
): IProjectQuestionTree[] => {
  if (!tree.length) {
    return [];
  }

  if (editingParentKey === tree[0].parent_id) {
    const index_position = tree.find(
      (node) => node.key === editingKey
    )?.index_position;
    tree.splice(index_position ?? 0, 1);
    return tree;
  }

  return tree.map((node) => {
    if (node.children && node.children.length > 0) {
      const updatedChildren = removeFromTree(
        editingKey,
        editingParentKey,
        node.children,
        null
      );
      return { ...node, children: updatedChildren };
    }

    return node;
  });
};

const updateAfterAddEditQuestion = (
  tree: IProjectQuestionTree[],
  editedKey: string | number,
  updateDetails: ISaveQuestionDetails
): IProjectQuestionTree[] => {
  if (!tree.length) {
    return [];
  }
  if (updateDetails.parent_id === tree[0].parent_id) {
    tree.forEach((node, index) => {
      if (node.key === editedKey) {
        node.data.question_id = updateDetails.id;
        node.data.question = updateDetails.description;
        node.data.variable = updateDetails.variable;
        node.key = updateDetails.id;
        node.title = updateDetails.description;
      }
    });
    return tree;
  }

  return tree.map((node) => {
    if (node.children && node.children.length > 0) {
      const updatedChildren = updateAfterAddEditQuestion(
        node.children,
        editedKey,
        updateDetails
      );
      return { ...node, children: updatedChildren };
    }
    return node;
  });
};

export const useMakeQuestionTree = makeQuestionTree;
export const doUpdateTreeAfterSort = updateTreeAfterSort;
export const doMoveChildElementOfTree = moveChildElementOfTree;
export const doQuestionTreeToFlattenQuestionList = questionTreeToFlattenQuestionList;
export const doAddQuestionToTree = addQuestionToTree;
export const doRemoveFromTree = removeFromTree;
export const doUpdateAfterAddEditQuestion = updateAfterAddEditQuestion;
export const doFindQuestionNodeFromTreeByKey = findNodeByKey

// update
export const canBeUpdateTheCurrentDropPosition = canBeUpdateTheDropPosition;
export const getMaxDepthOfQuestionTree = findMaxDepthOfTree;

export const useProjectDetailsStore = useStore;
