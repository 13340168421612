import { SelectProps } from 'antd';
import { create } from 'zustand';
import {
    ICreateEditProjectDetails,
    IProjectDetails,
    IProjectPermissionsData,
    IProjectListData,
    IProjectRoleMasterData,
    IResponseProjectDetails,
    IResponseProjectRoleMasterData,
    IResponseProjectTagListData,
    IToastMessages
} from '../models/interface';
import {
    IOrganizationGroup,
    IOrganizationUser,
    IResponseOrganizationGroupData,
    IResponseOrganizationUserData
} from '../models/interface/organization.interface';
import { OrganizationService, ProjectListService } from '../services';
import { IRecentProjectListData } from '../orgDashboard/components/RecentProjectList';
import { MenuKeys } from '../models/enums/menuKey.enums';

export type UniqueCheckStatus = null | 'checking' | 'exist' | 'unique';
export interface DrawerOptions {
    title: string;
    submitBtnLabel: string;
    key: number;
    data: null | IProjectListData | IRecentProjectListData;
    toastMessage: null | IToastMessages;
}
interface ProjectListState {
    tagList: SelectProps['options'] | null;
    drawerState: boolean;
    projectRoleData: IProjectRoleMasterData[] | null;
    projectAllRoleData: IProjectRoleMasterData[] | null;
    directPermissionsData: IProjectPermissionsData[] | null;
    userList: IOrganizationUser[] | null;
    memberList: IOrganizationUser[] | null;
    groupList: IOrganizationGroup[] | null;
    slugExists: UniqueCheckStatus;
    slugCheckOnSubmit: UniqueCheckStatus;
    drawerOptions: DrawerOptions;
    projectDetails: IProjectDetails | null;
    formLoader: boolean;
    projectCreateEditStatus: null | ICreateEditProjectDetails | 'UNKNOWN_ERROR';
    gearAction: boolean;

    resetState: () => void;
    setTagList: (tags: SelectProps['options'] | null) => void;
    setDrawerState: (state: boolean) => void;
    setProjectRoleData: (roles: IProjectRoleMasterData[] | null) => void;
    setProjectAllRoleData: (roles: IProjectRoleMasterData[] | null) => void;
    setProjectDirectPermissionsData: (directPermissions: IProjectPermissionsData[] | null) => void;
    setMemberList: (members: IOrganizationUser[] | null) => void;
    setUserList: (users: IOrganizationUser[] | null) => void;
    setGroupList: (groups: IOrganizationGroup[] | null) => void;
    setSlugExists: (state: UniqueCheckStatus) => void;
    setSlugCheckOnSubmit: (state: UniqueCheckStatus) => void;
    setDrawerOptions: (key?: number, projectInfo?: IProjectListData | IRecentProjectListData) => void;
    getDrawerDetails: (
        roleList: IProjectRoleMasterData[] | null,
        projectSlug?: string, 
        showMembers?: boolean
    ) => Promise<void>;
    setFormLoader: (state: boolean) => void;
    setProjectCreateEditStatus: (data?: ICreateEditProjectDetails) => void;
    setGearAction: (data: boolean) => void;
}

const useStore = create<ProjectListState>((set) => ({
    // states
    tagList: null,
    drawerState: false,
    projectRoleData: null,
    projectAllRoleData: null,
    directPermissionsData: null,
    userList: null,
    memberList: null,
    groupList: null,
    slugExists: null,
    slugCheckOnSubmit: null,
    drawerOptions: {
        title: 'Create New Project',
        submitBtnLabel: 'Create New Project',
        key: 0,
        data: null,
        toastMessage: null
    },
    projectDetails: null,
    formLoader: false,
    projectCreateEditStatus: null,
    gearAction: false,

    //actions
    resetState: () => {
        set(() => ({
            tagList: null,
            groupList: null,
            userList: null,
            slugCheckOnSubmit: null,
            slugExists: null,
            drawerOptions: {
                title: 'Create New Project',
                submitBtnLabel: 'Create New Project',
                key: 0,
                data: null,
                toastMessage: null
            },
            projectDetails: null
        }));
    },
    setTagList: (tags) => {
        set(() => ({
            tagList: tags ?? []
        }));
    },
    setDrawerState: (state) => {
        set(() => ({
            drawerState: state
        }));
    },
    setProjectRoleData: (roles) => {
        set(() => ({
            projectRoleData: roles
        }));
    },
    setProjectAllRoleData: (roles) => {
        set(() => ({
            projectAllRoleData: roles
        }));
    },
    setProjectDirectPermissionsData: (directPermissions) => {
        set(() => ({
            directPermissionsData: directPermissions
        }));
    },
    setMemberList: (members) => {
        set(() => ({
            memberList: members
        }));
    },
    setUserList: (users) => {
        set(() => ({
            userList: users ?? []
        }));
    },
    setGroupList: (groups) => {
        set(() => ({
            groupList: groups ?? []
        }));
    },
    setSlugExists: (state) => {
        set(() => ({
            slugExists: state
        }));
    },
    setSlugCheckOnSubmit: (state) => {
        set(() => ({
            slugCheckOnSubmit: state
        }));
    },
    setGearAction: (data) => {
        set(() => ({
            gearAction: data
        }));
    },
    setDrawerOptions: (key, projectInfo) => {
        const titles: any = {
            0: 'Create New Project'
        };

        titles[MenuKeys.EDIT_PROJECT] = `Edit ${projectInfo?.title ?? ''}`;
        titles[MenuKeys.MANAGE_PROJECT_USERS] = `Manage Users for ${projectInfo?.title ?? ''}`;

        const toastMessages: any = {
            0: {
                loading: 'Creating project...',
                success: 'Project created successfully!',
                error: 'Project creation failed. Please try again!'
            }
        };

        toastMessages[MenuKeys.EDIT_PROJECT] = {
            loading: 'Updating project...',
            success: 'Project updated successfully!',
            error: 'Error in updating project. Please try again!'
        };
        toastMessages[MenuKeys.MANAGE_PROJECT_USERS] = {
            loading: 'Updating groups and users...',
            success: 'Groups and users updated successfully!',
            error: 'Error in updating groups and users. Please try again!'
        };

        set(() => ({
            drawerOptions: {
                key: key ?? 0,
                title: titles[key ?? 0],
                submitBtnLabel: key ? 'Save Changes' : 'Create New Project',
                data: projectInfo ?? null,
                toastMessage: toastMessages[key ?? 0]
            }
        }));
    },

    getDrawerDetails: async (roleList, projectSlug, showMembers) => {
        set(() => ({ formLoader: true }));
        console.log(showMembers);
        
        const groupListResult = showMembers ? new OrganizationService().getOrganizationGroupList() : Promise.resolve(null);
        const userListResult = showMembers ? new OrganizationService().getOrganizationUserList() : Promise.resolve(null);
        const tagListResult = new ProjectListService().getProjectTagListData();
        const projectRoleResult = (!roleList || !showMembers)
            ? new ProjectListService().getProjectRoleMasterData({ show_all: false })
            : Promise.resolve(null);
        const projectDetailsResult = projectSlug
            ? new ProjectListService().getProjectDetailsData(projectSlug)
            : Promise.resolve(null);
        Promise.all([
            groupListResult,
            userListResult,
            tagListResult,
            projectDetailsResult,
            projectRoleResult
        ])
            .then((responses) => {
                // Handle the responses of all API calls
                const dataPromises = responses.map((response) => response?.data.data);
                return dataPromises;
            })
            .then((data) => {
                let updatedState: any = {
                    groupList: (data[0] as IResponseOrganizationGroupData)?.groupList,
                    userList: (data[1] as IResponseOrganizationUserData)?.userList,
                    tagList: (data[2] as IResponseProjectTagListData)?.tagList,
                    projectDetails: (data[3] as IResponseProjectDetails)?.project_details
                };
                if (!roleList) {
                    updatedState['projectRoleData'] = (
                        data[4] as IResponseProjectRoleMasterData
                    )?.roleList;
                }
                set(() => ({
                    ...updatedState,
                    formLoader: false
                }));
            })
            .catch((error) => {
                set(() => ({ projectCreateEditStatus: 'UNKNOWN_ERROR', drawerState: false }));
            });
    },

    setFormLoader: (state) => {
        set(() => ({
            formLoader: state
        }));
    },

    setProjectCreateEditStatus: (data) => {
        set(() => ({ projectCreateEditStatus: data ?? null }))
    }

}));

export const useCreateNewProjectStore = useStore;
