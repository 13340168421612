import { RoutesEnum } from "../../../models/enums";
import { IApiBaseResponse } from "../../../models/interface";
import { ApiService } from "../../shared/services";
import { ISiteProjectListData } from '../models/interface';
import { IRecentProjectListData } from '../orgDashboard/components/RecentProjectList';

export class OrgDashboardService {
  public getRecentProjectList = async () => {
    return (await new ApiService().privateClient()).get<
      IApiBaseResponse<{projects: IRecentProjectListData[];}>
    >(RoutesEnum.ORG_DASHBOARD_RECENT_PROJECT_LIST, {});
  };

  public getProjectChartList = async () => {
    return (await new ApiService().privateClient()).get<
      IApiBaseResponse<{
        chart_data: {
          total_projects: number;
          divided_projects: {
            name: string;
            value: number | null;
            percentage: null | string;
            role: string;
          }[];
        };
      }>
    >(RoutesEnum.ORG_DASHBOARD_PROJECT_CHART_LIST, {});
  };

  public getRecentPublishedProjectList = async () => {
    return (await new ApiService().privateClient()).get<IApiBaseResponse<{published_projects: ISiteProjectListData[];}>>(RoutesEnum.ORG_DASHBOARD_RECENT_PROJECT_PUBLISHED_LIST, {});
  };
}
