export enum MenuKeys {
    /**
     * keys for project action
     * NOTE: 0 must not be assigned to a key
     */
    EDIT_PROJECT = 1,
    MANAGE_PROJECT_USERS = 2,
    CLONE_PROJECT = 3,
    PUBLISH_PROJECT = 4,
    UNPUBLISH_PROJECT = 5,
    DELETE_PROJECT = 6,
    CODING_REVIEW = 7,
    ERROR_SAMPLING = 8,
    SUGGESTIVE_TEXTS_HISTORY = 9

}

export enum RecordMenuKeys {
    /**
     * keys for record action
     * NOTE: 0 must not be assigned to a key
     */
    EDIT_RECORD = 1,
    HIDE = 2,
    DELETE_RECORD = 3,
    MANAGE_USERS = 4,
    AMEND_RECORD = 5,
    CLONE_RECORD = 6,
    RESTORE_RECORD = 7,
    UNHIDE_RECORD = 8,
    GENERATE_SUGGESTIVE_TEXTS = 9
}

export enum UserListMenuKeys {
    /**
     * keys for record action
     * NOTE: 0 must not be assigned to a key
     */
    VIEW_USER = 1,
    EDIT_USER = 2,
    DELETE_USER = 3,

}

export enum GroupCardMenuKeys {
    /**
     * keys for group action
     * NOTE: 0 must not be assigned to a key
     */
    CREATE_GROUP = 1,
    EDIT_GROUP = 2,
    DELETE_GROUP = 3,

}

export enum SiteListMenuKeys {
    UNPUBLISH_PROJECT = 1,
    UNLINK_PROJECT = 2,
}

export enum RankingCardMenuKeys {
    /**
     * keys for group action
     * NOTE: 0 must not be assigned to a key
     */
    CREATE_RANKING_TEMPLATE = 1,
    EDIT_RANKING_TEMPLATE = 2,
    DELETE_RANKING_TEMPLATE = 3,

}


export enum PublishedProjectStatusKeys {
    /**
     * keys for queue status
     */
    TO_DO = 'To Do',
    IN_PROGRESS = 'In Progress',
    SUCCESS = 'Success',
    FAILED = 'Failed',

}
