import { RoutesEnum } from "../../../models/enums";
import { IApiBaseResponse } from "../../../models/interface";
import { ApiService } from "../../shared/services";
import {
    IResponseOrganizationGroupData,
    IResponseOrganizationUserData, IRequestOrgUserListData,
    IResponseUserList, IRequestOrgUserChangeStatus, IResponseOrgUserChangeStatus,
    IRequestOrgUserChangeRole, IResponseOrgUserChangeRole, IProjectReportType, IRequestOrgAssociatedSiteUser, IResponseOrgAssociatedSiteUser
} from '../models/interface';

export class OrganizationService {

    public async getOrganizationUserList() {
        return (await new ApiService().privateClient()).get<IApiBaseResponse<IResponseOrganizationUserData>>(RoutesEnum.ORG_GET_USER_LIST, {})
    }

    public async getOrganizationGroupList() {
        return (await new ApiService().privateClient()).get<IApiBaseResponse<IResponseOrganizationGroupData>>(RoutesEnum.ORG_GET_FILTER_GROUP_LIST, {})
    }
    
    public async getOrganizationUserListData(params: IRequestOrgUserListData) {
        return (await new ApiService().privateClient()).get<IApiBaseResponse<IResponseUserList>>(RoutesEnum.ORG_GET_USER_LIST_SEARCH_FILTER, { params })
    }
    
    public async postOrganizationUserChangeStatus(params: IRequestOrgUserChangeStatus) {
        return (await new ApiService().privateClient()).post<IApiBaseResponse<IResponseOrgUserChangeStatus>>(RoutesEnum.ORG_MANAGE_USER_CHANGE_STATUS, params)
    }
    
    public async postChangeUserRole(params: IRequestOrgUserChangeRole) {
        return (await new ApiService().privateClient()).post<IApiBaseResponse<IResponseOrgUserChangeRole>>(RoutesEnum.ORG_MANAGE_USER_CHANGE_ROLE, params)
    }

    public async getProjectReportTypeList() {
        return (await new ApiService().privateClient()).get<IApiBaseResponse<{ reportTypeList: IProjectReportType[] }>>(RoutesEnum.ORG_PROJECT_REPORT_TYPE_LIST, {})
    }

    public async getCheckAssociatedSites(params: IRequestOrgAssociatedSiteUser) {
        return (await new ApiService().privateClient()).get<IApiBaseResponse<IResponseOrgAssociatedSiteUser>>(RoutesEnum.ORG_MANAGE_USER_CHECK_ASSOCIATED_SITES, { params })
    }
}

