import { Result } from "antd";
import { containsString } from "modules/shared/utility";
import { Fragment, useCallback, useEffect } from "react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { useOrganizationRoutes } from "routes/organizationRoute";
import { useProjectDetailsStore, useProjectRecordsStore } from "../../store";
import { LeftOutlined } from "@ant-design/icons";
import PageLoader from "modules/shared/components/PageLoader";
import NotFound from "modules/shared/components/NotFound";

const ProjectLayout = () => {
  const { project_slug } = useParams();

  const location = useLocation();

  const {
    clearProjectDetails,
    getProjectDetails,
    projectDetails,
    questionCount,
    isLoadingProjectDetails,
  } = useProjectDetailsStore();
  const { resetState } = useProjectRecordsStore();
  const { organizationBaseRouteUrl } = useOrganizationRoutes();
  const { actualRecordsCount } = useProjectRecordsStore();

  const getAndSetProjectDetails = useCallback(() => {
    clearProjectDetails();
    getProjectDetails(project_slug ?? "").catch((error) => console.log(error));
  }, [getProjectDetails, clearProjectDetails, project_slug]);

  useEffect(() => {
    getAndSetProjectDetails();
  }, [getAndSetProjectDetails]);

  useEffect(() => {
    return () => {
      clearProjectDetails();
      resetState();
    };
  }, [clearProjectDetails, resetState]);

  if (isLoadingProjectDetails) {
    return <PageLoader />;
  }
  
  if (!projectDetails) {
    return <NotFound />;
  }

  if (
    containsString(location.pathname, [
      "coding-review",
      "error-sampling",
      "publish",
    ])
  ) {
    if (
      !(actualRecordsCount ?? projectDetails?.record_count) ||
      !(questionCount ?? projectDetails?.question_count)
    ) {
      return (
        <Fragment>
          <div className="pageTopSection">
            <div className="backLink">
              <Link
                to={
                  organizationBaseRouteUrl + `/project/${projectDetails?.slug}`
                }
              >
                <LeftOutlined />
                Back to Project Home
              </Link>
            </div>
          </div>
          <div className="verifyMsgWrap" style={{ minHeight: "80vh" }}>
            <Result
              status="403"
              title="Access denied"
              subTitle="You need at least 1 question and 1 record in order to access this page"
            />
          </div>
        </Fragment>
      );
    }
  }

  return (
    <Outlet />
  );
};

export default ProjectLayout;
